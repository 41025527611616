<template>
	<div
		class="room"
		:class="[
			'room--'+data.spaceType,
			{'room--joined': inRoom},
			{'room--visible': visible},
			{'room--viewing': viewingRoom},
			{'room--in-search': inSearch},
			{'room--has-bg': backgroundImage},
			{'no-access': !hasAccess}
		]"
		:style="style"
		@click.stop="handleClick"
	>
		<img v-if="isCreate" src="@/assets/conferencing-icons/create-room.svg"/>
		<div class="room-content" v-else>
			<div v-if="isHub" class="room-content-hub">
				<div v-if="logo" class="room-content-hub__logo" :style="`background-image: url(${logo})`"/>
				<div v-else class="room-content-hub__intials">{{areaInitials}}</div>
			</div>
			<div class="room-content__bg" v-else-if="backgroundImage">
				<img class="room-content__bg-image" aria-hidden="true" :src="backgroundImage"/>
			</div>
			<div v-if="!hideName" class="room-content__name">{{name}}</div>
			<div class="room-content__button" v-if="viewingRoom && !isHub" :class="{disabled: !hasAccess}">
				<img v-show="hasAccess" :src="require(`@/assets/${buttonContent.icon}`)"/>
				{{hasAccess ? buttonContent.text : 'Members Only'}}
			</div>
			<div class="room-content__online">{{isPortal ? '(' : ''}}{{data.online}}{{isPortal ? ')' : ''}}</div>
		</div>
		<transition-group>
			<UserIcon
				class="room__user"
				v-for="({userId, status}, index) in usersToShow"
				v-show="!highlightedUser || userId === highlightedUser"
				:key="userId"
				:userId="userId"
				:userStatus="status"
				:style="getUserPosition(index)"
			/>
		</transition-group>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { processMediaURL, colorIsLight, initaliseAreaName } from '@/functions/utility.js';
import { capRoomNamesToFit } from '@/functions/app.js';

import CreateRoomModal from '../modals/nav/CreateRoomModal.vue';
import UserIcon from '../UserIcon.vue';

const TAU = (Math.PI * 2);
const linkButtonContent = {text: 'Explore', icon: 'global-icons/eye-show-small.svg'}
const buttonContentMap = {
  spacein: {
    text: 'Join',
    icon: 'notification-icons/camcorder-white.svg'
  },
  livestream: {
    text: 'Watch',
    icon: 'conferencing-icons/play.svg'
  },
	portal: {
    text: 'Jump',
    icon: 'global-icons/jump-space.svg'
  },
  link: linkButtonContent,
  internal: linkButtonContent,
  // legacy, probably deprecated
  networking: linkButtonContent,
  unknown: linkButtonContent,
}

export default {
	name: 'Room',
	components: { UserIcon },
	data: () => ({
		visible: false,
		mobileNameLimit: 14,
		randomRadians: Math.random() * TAU
	}),
	props: {
		data: Object,
		index: Number
	},
	computed: {
		...mapGetters('search', ['highlightedRoom', 'highlightedUser']),
		...mapGetters([
			'search/query',
			'search/rooms',
			'search/users',
			'getSpacePageId',
			'getCurrentUsersRoomId',
			'getRoomBGColor',
			'getCurrentAreaLogo',
			'getCurrentAreaTitle',
			'current_user/id',
			'viewport/isMobile',
			'getSpaceIfPortal',
			'isAuthorisedLocation',
		]),
		style({ data:{position:{x,y,r} } }){
			return {
				'--x': x+'px', '--y': y+'px', '--r': r,
				'--schemeBG': this.BGcolor,
				'--schemeText': this.schemeText
			};
		},
		buttonContent: vm => vm.isPortal ? buttonContentMap['portal'] : buttonContentMap[vm.data.conferenceType],
		BGcolor: vm => vm.getRoomBGColor(vm.data),
		inRoom: vm => vm.getCurrentUsersRoomId === vm.data.id,
		viewingRoom: vm => vm.getSpacePageId === vm.data.id || vm.getSpacePageId === vm.data.reference,
		isHub: vm => vm.data.spaceType === 'hub',
		isCreate: vm => vm.data.spaceType === 'create_space',
		isPortal: vm => vm.data.spaceType === 'portal' || Number(vm.data.spaceType) === 6,
		logo: function() {
			return processMediaURL(this.getCurrentAreaLogo)
		},
		backgroundImage: function() {
			return processMediaURL(this.data.imageUrl)
		},
		schemeText: function() {
			// When there's a background image, use the user-selected text color
			if (this.backgroundImage) {
				return this.textColor
			} else {
				// Otherwise figure it out from the background
				return colorIsLight(this.BGcolor) ? 'black' : 'white'
			}
		},
		hideName: vm => vm.data.hideName,
		textColor: vm => vm.data.textColor || 'white',
		areaInitials: vm => initaliseAreaName(vm.getCurrentAreaTitle),
		usersInSearch: vm => vm['search/users'].filter(user => user.whereabouts?.space?.id === vm.data.id),
		usersToShow: vm => vm['search/query'] ? vm.usersInSearch : (vm.inRoom ? [{userId: vm['current_user/id']}] : []),
		inSearch(){
			if(this.highlightedRoom || this.highlightedUser) return this.highlightedRoom === this.data.id;
			else return this['search/rooms'].find(r => r.id === this.data.id) || this.usersInSearch.length;
		},
		name(){
			let name = this.data.name;
			if(this['viewport/isMobile']) return name.length >= this.mobileNameLimit ? name.slice(0, this.mobileNameLimit)+'...' : name
			else return capRoomNamesToFit(name).replaceAll('<br>', '\n');
			// todo: can probably do better than capRoomNamesToFit
		},
		hasAccess: vm => vm.isPortal && vm.viewingRoom ? vm.isAuthorisedLocation(vm.data.id) : true,
	},
	methods: {
		...mapActions([
			'modal/open',
			'routing/goToViewRoom',
			'handleSwitchRoom',
			'handleEnterSpacePortal'
		]),
		getUserPosition(index){
			// offset is where the first user is positioned, in radians
			// uses a fixed offset if the current user is in the room, to ensure their icon is always in the same place
			// otherwise uses a random offset, for ~variety~, I guess
			// the random offset is only calculated once per room though, to keep things consistent within a room
			let offset = this.inRoom ? -0.9 : this.randomRadians;
			// by dividing TAU by the number of users, we can evenly space the users around the circle
			let radiansPerUser = TAU / this.usersToShow.length
			let radians = (index * radiansPerUser) + offset;
			// here we're finding the normalised Vector2 for a given angle in radians
			// then offsetting + scaling it by the radius of the circle
			// 50 is the "radius" because we're using % for positioning
			let x = 50+(Math.cos(radians)*50);
			let y = 50+(Math.sin(radians)*50);

			return {
				left: x+'%',
				top: y+'%'
			}
		},
		handleClick(){
			if(this.isCreate) this['modal/open']([CreateRoomModal])
			else if(this.isPortal && this.viewingRoom) {
				if(!this.hasAccess) return //dont allow users with no space access to join/enter
				this.handleEnterSpacePortal()
			}
			else if((this.inRoom || this.viewingRoom) && !this.isHub) this.handleSwitchRoom(this.data.id)
			else if(!this.viewingRoom) this['routing/goToViewRoom'](this.data.id)
		}
	},
	mounted(){
		setTimeout(() => this.visible = true, 250 + (15 * this.index))
	}
}
</script>

<style lang="scss" scoped>
@import '~@/scss/components/space_overview/room.scss';
</style>;
