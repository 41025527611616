<template>
  <div class="rooms" v-show="rooms.length" @click="containerClicked">
    <SearchBar class="rooms-search-bar" :hollow="true"/>
    <div
      class="rooms-overlay"
      v-if="getCurrentSpacePageData.id || getLoadingSpace"
    />
    <div class="rooms-container">
      <!-- the if ensures no errors/visual glitches -->
      <transition-group v-if="areaLoaded && filteredRooms[0] && filteredRooms[0].position">
        <Room
          v-for="(r, i) in filteredRooms"
          :key="r.id"
          :data="r"
          :index="i"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SearchBar from '../search/SearchBar.vue';
import Room from './Room.vue';

export default {
  name: 'Rooms',
  components: { Room, SearchBar },
  computed: {
    ...mapGetters(['getCurrentSpacePageData', 'getLoadingSpace', 'getCurrentAreaId', 'hideUnauthorisedPortals', 'authorisedLocations']),
    ...mapGetters('sidebar', ['panelId', 'prevPanelId']),
    ...mapGetters('viewport', ['isDesktop']),
    ...mapGetters({ rooms: 'getSpaces' }),
    areaLoaded: vm => vm.$route.params.areaId === vm.getCurrentAreaId,
    filteredRooms() {
      return this.hideUnauthorisedPortals ? this.rooms.filter(r => r.spaceType === 'portal' ? this.authorisedLocations.includes(r.id) : true) : this.rooms
    },
  },
  methods: {
    ...mapActions(['recalculateRoomLayout', 'stopViewingRoom', 'sidebar/openPrev', 'sidebar/open', 'search/clear']),
    containerClicked(){
      this['search/clear']()
      //if mob and sidebar is open - dont need to stop viewing room
      if(this.panelId && !this.isDesktop) return
      //close/clear search when clicking on lobby background + show previous panel (desktop only)
      if(!this.getCurrentSpacePageData?.id) {
        if(this.panelId === 'search') this['sidebar/openPrev']()
        return
      }
      this.stopViewingRoom()
      //open prev panel on stop viewing room if not search (as cleared already), else open info panel
      if(this.prevPanelId !== 'search') this['sidebar/openPrev']()
      else this['sidebar/open']('info')
    }
  },
  mounted(){
    this.recalculateRoomLayout();
  }
};
</script>

<style scoped lang="scss">
.rooms {
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  padding: 7.5vh 50px;

  // it's okay for padding to change on resize, but don't change it under any other circumstance
  // this is because the room positions are only recalculated on resize
  // padding is set such that there's the same gap at the top and bottom of the rooms
  // the trick is that the bottom bar takes up space, the search bar does not (absolute positioning)
  // so needs lots of padding to account for it
  @include mobile-only {
    padding: 100px 5px 30px;
  }

  &-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.65);
    z-index: 2;
    cursor: pointer;
    pointer-events: none;
  }

  &-search-bar {
    @include desktop-up {
      display: none;
    }
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
  }
}
</style>
