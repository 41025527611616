<template>
  <div class="lobby-page" data-v-step="0" :class="[portalAnimation, {'show-lobby': showLobby, oneCol: !(ifJoining && !isTabletOrDown && !ifAuthenticated)}]">

    <div v-if="loadingLobby">
    </div>

    <fade-transition v-else-if="showLobby">
      <div class="space-lobby">
        <div class="page-header">
          <img src="@/assets/global-icons/spacein_logo_white.svg" alt="" @click="handleLogoClick">
        </div>
        <div class="room-lobby">
          <div class="room-lobby__left" :class="{joining: ifJoining}" v-if="ifJoining && !isTabletOrDown && !ifAuthenticated">
            <div class="space-info">
              <SpaceListing
                :space="spaceData"
                variant="space-lobby"
              />
            </div>
          </div>

          <div class="room-lobby__right">
            <div v-if="!validInvite" class="invalid">
              {{invalidMessage}}
            </div>
            <div v-else-if="ifJoining" class="join-room">
              <SpaceLogo class="space-listing-logo" :logo="spaceData.logo" :title="spaceData.title" diameter="120px" v-if="isTabletOrDown || ifAuthenticated"/>
              <div class="header">
                <p>Join Space</p>
                <h3 class="room-name">{{spaceName}}</h3>
              </div>

              <div v-if="!ifAuthenticated && guestData && guestData.username && !hideSuggestedGuestLogin" class="guest existing-guest">
                <p><b>Do you wish to reuse existing guest account?</b></p>
                <div class="guest-card">
                  <p>{{guestData.username}}</p>
                </div>

                <div class="buttons">
                  <btn @click="hideGuestSuggestionHandler" rounded wide large colour="ghost">
                    <template #text>
                      <b>Switch User</b>
                    </template>
                  </btn>
                  <btn @click="() => enterSpaceAsExistingGuest()" rounded wide large colour="green">
                    <template #text>
                      <b>Enter Space</b>
                    </template>
                  </btn>
                </div>
              </div>
              <div v-else-if="!ifAuthenticated" class="guest">
                <inputfield
                  large
                  rounded
                  placeholder="Type in your name"
                  :customClasses="['bg-light-black', 'text-white', 'text-bold']"
                  v-model="guestName"
                  @keypress.enter="joinRoom"
                >
                </inputfield>

                <btn @click="enterSpace" :disabled="!guestName" rounded wide large colour="green" style="margin-top: 1rem;">
                  <template #text>
                    <b>Enter Space</b>
                  </template>
                </btn>

                <div class="guest__terms">
                  <small>
                    By continuing I agree to SPACEIN
                    <span @click.stop><a target="_blank" href="https://spacein.co.uk/terms-and-conditions" class="text-white">
                      <b>Terms & Conditions</b>
                    </a></span>
                    and
                    <span @click.stop><a target="_blank" href="https://spacein.co.uk/privacy-policy" class="text-white">
                      <b>Privacy Policy.</b>
                    </a>
                    </span>
                  </small>
                </div>

                <p class="already-member">Already a member? <span class="login" @click="goToLogin"><b>Login</b></span></p>
              </div>
              <div v-else class="current-user">
                <div class="user-details">
                  <user-icon :currentUser="true" userStatus="offline" />
                  <div class="info">
                    <p class="full-name">{{fullName}}</p>
                    <p class="email">{{email}}</p>
                  </div>
                </div>
                <div class="buttons">
                  <btn class="logout" @click="onLogout" rounded wide large colour="ghost">
                    <template #text>
                      <b>Logout</b>
                    </template>
                  </btn>
                  <btn @click="enterSpace" rounded wide large colour="green">
                    <template #text>
                      <b>Enter Space</b>
                    </template>
                  </btn>
                </div>
              </div>
            </div>
            <div v-if="(!ifJoining && isExternalGuest) || !validInvite" class="footer">
              <hr>
              <div class="footer__content">
                <p v-if="isExternalGuest">Want to try SPACEIN for yourself? <br> Create your own community.</p>
                <btn v-if="isExternalGuest" @click="() => registerAsGuestHandler()" rounded wide large colour="yellow">
                  <template #text>
                    <b>Sign up to SPACEIN</b>
                  </template>
                </btn>
                <btn v-if="ifAuthenticated && !isExternalGuest" @click="goToHome" rounded wide large colour="yellow">
                  <template #text>
                    <b>Go to my Spaces</b>
                  </template>
                </btn>

                <btn v-if="ifAuthenticated && !isLeaving" @click="onLogout" class="logout" rounded wide large colour="ghost">
                  <template #text>
                    <b>Logout</b>
                  </template>
                </btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fade-transition>

    <div class="space-overview" v-else>
      <fade-transition>
        <!-- done as a v-if to save bandwidth for the user -->
        <div class="space-cover-image" v-if="isDesktop" v-show="bannersrc && bannerLoaded">
          <img :src="bannersrc" alt="space cover image" @load="bannerLoaded = true" v-if="bannersrc" />
        </div>
      </fade-transition>
      <rooms v-if="getAreaStopSpacesBackup"/>
    </div>
  </div>
</template>

<script>
import { processMediaURL } from '@/functions/utility.js';
import Rooms from '@/components/space_overview/Rooms.vue';
import FadeTransition from '@/components/transitions/FadeTransition.vue';
import SpaceListing from '@/components/home_page/SpaceListing.vue';
import SpaceLogo from '@/components/atoms/SpaceLogo.vue';

import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'SpaceLobby',
  components: {
    Rooms,
    FadeTransition,
    SpaceListing,
    SpaceLogo,
  },
  props: {
    pageType: String
  },
  data: () => ( {
    // space lobby
    guestName: '',

    // space overview
    bannerLoaded: false,
    roomLayoutTimeout: 0,
    lastLayoutRecalc: 0,
    portalAnimation: false,
  } ),
  computed: {
    ...mapGetters([
      'getAreaStopSpacesBackup',
      'getCurrentAreaBanner',
      'loading/isFirstLobbyLoad',
      'getSpaceClicked',
      'getCurrentAreaId',
      'enterJumpSpace'
    ]),
    ...mapGetters({
      dimensions: 'viewport/dimensions'
    }),
    ...mapGetters('api/auth', {
      ifAuthenticated: 'authenticated', //if registered user
    }),
    ...mapGetters('routing', ['getParamValue']),
    ...mapGetters('current_user', ['fullName', 'email', 'isExternalGuest']),
    ...mapGetters('guest/space', ['loadingLobby', 'spaceId', 'spaceName', 'spaceData', 'isLeaving', 'isInvalid', 'invalidReason', 'showLobby']),
    ...mapGetters('guest', ['isEnteringAfterAuth', 'isRejoiningAfterAuth', 'hideSuggestedGuestLogin']),
    ...mapGetters({
      guestData: 'guest/guestStorageData' //guest data from local storage
    }),
    ...mapGetters('viewport', ['isDesktop', 'isTabletOrDown']),
    vm: vm => vm,
    bannersrc: vm => processMediaURL(vm.getCurrentAreaBanner),
    validInvite() {
      return !!this.spaceId && !this.isInvalid
    },
    ifJoining() { //if user is trying to join before clicking join room
      return this.validInvite
    },
    invalidMessage() {
      let message = "This invite is not valid. Please request a new one."

      switch (this.invalidReason) {
        case "no_token_provided":
          break
        case "not_found":
          break
        case "space_not_found":
          message = "This Room can't be found. Please try again."
          break
      }

      return message
    },
  },
  watch: {
    isDesktop(val){
      // doing this because the sidebar transitions its width on desktop, but has to always be width 100% on mobile
      // so the positioning of the rooms gets mucked up when resizing
      if(val) setTimeout(this.recalculateRoomLayout, 200);
    },
    dimensions() {
      this.onWindowResize()
    },
    getCurrentAreaId() {
      this.initLobbyLoad()
    },
    isEnteringAfterAuth(newVal) {
      //join if switches to true
      if(newVal) this.checkIfEnteringAfterAuth()
    },
    isRejoiningAfterAuth(newVal) {
      //join if switches to true
      if(newVal) this.checkIfRejoiningAfterAuth()
    },
    showLobby(newVal) {
      if(!newVal) { //enter space
        this.initLobbyLoad()
      } else { //space lobby
        this.handleCheckForGuestInStorage()
        this.checkIfEnteringAfterAuth()
        this.checkIfRejoiningAfterAuth()
      }
    },
    enterJumpSpace(newVal, oldVal) {
      if(newVal && !oldVal) this.portalAnimation = 'portal-in'
      else if(oldVal && !newVal) {
        this.portalAnimation = 'portal-out'
        setTimeout(() => {
          this.portalAnimation = false
        },1000)
      }
    },
  },
  methods: {
    ...mapMutations(['loading/FIRST_LOBBY_LOAD', 'setSpaceClicked', 'guest/HIDE_SUGGESTED_GUEST_LOGIN']),
    ...mapActions(['recalculateRoomLayout', 'tour/updateOnLoad', 'analytics/enterSpace', 'enterAreaHandlerAsGuest']),
    ...mapActions('routing', ['goToHome', 'goToLogin']),
    ...mapActions('api/auth', ['logout']),
    ...mapActions('guest/space', ['handleEnterOpenSpaceAsGuest', 'enterSpaceAsExistingGuest', 'refreshLobby']),
    ...mapActions('guest', ['handleCheckForGuestInStorage', 'registerAsGuestHandler']),
    onWindowResize() {
      // to be used when the new room code is usable, since it has a transition to smooth things out
      clearTimeout(this.roomLayoutTimeout);

      if((Date.now() - this.lastLayoutRecalc) < 200) this.roomLayoutTimeout = setTimeout(this.recalculateRoomLayout, 200);
      else {
        this.lastLayoutRecalc = Date.now();
        setTimeout(this.recalculateRoomLayout(), 100)
      }
    },
    initLobbyLoad() { //on enter space
      if(this.mounted || !this.getCurrentAreaId) return
      if(this['loading/isFirstLobbyLoad']) {
        this['tour/updateOnLoad']
        this['analytics/enterSpace'](this.getSpaceClicked ? 'organic' : 'direct')
        this['loading/FIRST_LOBBY_LOAD'](false)
      }
      if(this.getSpaceClicked) this.setSpaceClicked(false)
    },

    //space lobby
    handleLogoClick() {
      if(this.ifAuthenticated) this.goToHome()
      else this.registerAsGuestHandler()
    },
    onLogout() {
      // Log out the user, requesting that we then go to the login page and redirect back to this URL
      // so we can keep the same invite token
      const currentUrl = window.location.pathname
      this.logout({
        type: 'loginThenRedirect',
        url: currentUrl
      })
    },
    enterSpace() {
      let name = this.guestName || this.fullName
      if(!name) return console.log('no name')
      this.handleEnterOpenSpaceAsGuest(name)
    },
    checkIfEnteringAfterAuth() {
      if(this.isEnteringAfterAuth) {
        // this.enterSpace()
      }
    },
    checkIfRejoiningAfterAuth() {
      if(this.isRejoiningAfterAuth) {
        // this.enterAreaHandlerAsGuest(this.spaceId)
      }
    },
    hideGuestSuggestionHandler() {
      this['guest/HIDE_SUGGESTED_GUEST_LOGIN'](true)
    },
  },
  mounted() {
    if(this.showLobby) {
      this.handleCheckForGuestInStorage()
      this.checkIfEnteringAfterAuth()
      this.checkIfRejoiningAfterAuth()
    } else {
      this.initLobbyLoad()
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.lobby-page {

  &.portal-in {
    background: black;

    & .space-overview {
      background: var(--primaryColour);
      transform: scale(0.93);
      border-radius: 1rem;
      animation: slideUp 0.4s ease 0.3s forwards;
    }
  }
  &.portal-out {
    background: black;
    animation: backgroundOut 0.2s ease 0.6s forwards;

    & .space-overview {
      animation: portalIn 0.6s ease forwards;
    }
  }
  .space-listing-logo {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: -85px;
  }
}
.space-overview {
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  grid-row: 1/3;
  transform: scale(1);
  transition: all 0.3s ease;
}
.space-info {
  display: grid;
  align-items: center;
  justify-content: center;
}

@keyframes slideUp {
  0% {transform: translate(0,0) scale(0.93)}
  100% {transform: translate(0,-100vh) scale(0.93)}
}
@keyframes portalIn {
  0% {transform: translate(0,-100vh) scale(0.93); border-radius: 1rem; background: var(--primaryColour);}
  60% {transform: translate(0,0) scale(0.93); border-radius: 1rem; background: var(--primaryColour);}
  100% {transform: translate(0,0) scale(1); border-radius: 0; background: var(--primaryColour);}
}
@keyframes backgroundOut {
  0% {background: black}
  100% {background: transparent;}
}

@import '~@/scss/pages/space.scss';
@import '~@/scss/pages/room_lobby.scss';
</style>
