import { render, staticRenderFns } from "./SpaceLobby.vue?vue&type=template&id=4751eebc&scoped=true"
import script from "./SpaceLobby.vue?vue&type=script&lang=js"
export * from "./SpaceLobby.vue?vue&type=script&lang=js"
import style0 from "./SpaceLobby.vue?vue&type=style&index=0&id=4751eebc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4751eebc",
  null
  
)

export default component.exports