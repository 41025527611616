<template>
    <div class="create-room-form">
      <div class="header-tabs" v-if="isPremiumSpace && userType >= 1">
        <div v-for="tab in createOptions"
             class="tab"
             :class="{'inactive-tab': tab !== currentTab}"
             @click="() => switchTab(tab)"
             :key="tab"
        >
          {{tab}}
        </div>
      </div>
      <modal-header v-else>
        Create Room
      </modal-header>

      <form @submit.prevent="openRoom" v-if="currentTab === 'Room'">
        <div class="form-group" style="margin-bottom: 1rem">
          <TextInput ref="roomNameInput" inset large label="Name your room" placeholder="Enter your room name" v-model="name"/>
          <p class="input__error" v-if="error.name">{{error.name}}</p>
        </div>

        <div class="create__type" v-if="userType >= 1 && !customizeScreenOpen">
          <div class="form__title"><b>Select Type of the Room</b></div>
          <div class="create__select">
            <div
              class="select__type tooltip"
              :class="{selected: picked == key, disabled: btnDisabled(key), 'select__type--full': btnFull(key)}"
              v-for="([name], key) in roomTypes"
              :key="key"
              @click="btnDisabled(key) ? null : picked = key"
            >
              {{name}}
              <span v-if="btnDisabled(key)" class="tooltiptext">Livestreaming is a premium feature, please contact support@spacein.co.uk to discuss options available</span>
            </div>
          </div>
        </div>

        <div v-if="linkType" class="form-group">
          <TextInput :label="linkType" inset placeholder="Enter Room URL" v-model="url"/>
          <p class="input__error" v-if="error.url">{{error.url}}</p>
        </div>

        <CustomiseSection
          v-if="customizeScreenOpen"
          :isCreate="true"
          :startAngle="angle"
          :updateAngle="updateAngle"
          :backgroundColour="backgroundColour"
          :updateBackground="updateBackground"
          :backgroundImage="backgroundImage"
          :newImageUploaded="newImageUploaded"
          :imageRemoved="imageRemoved"
          :showName="showName"
          :updateShowName="updateShowName"
          :textColor="textColor"
          :updateTextColour="updateTextColour"
        />
      </form>

      <form class="portal-section" v-else>
        <div class="info" v-if="!customizeScreenOpen">
          <div class="portal__title"><b>Select the Space</b></div>
          <p>The Portal creates a link to another Space. <br>Only Spaces in which you are an admin appear in this list.</p>
        </div>

        <div class="spaces-list" v-if="!customizeScreenOpen">
          <LoadingIcon v-if="loadingSpaces" maxHeight="25vh" />
          <div v-else-if="!userSpaces.length" class="error">
            There was an error getting your Spaces. <br> Please try again.
          </div>
          <div v-else class="spaces">
            <div v-for="space in userSpaces"
                 class="space"
                 :class="{current: selectedSpaceId && selectedSpaceId == space.id}"
                 @click="() => selectJumpSpace(space.id)"
                 :key="space.id"
            >
              <div class="icon" :style="{background: `${space.logo ? 'white': space.primaryColour}`}">
                <img class="img" v-if="space.logo" :src="space.logo" alt="space logo" />
                <div class="img" v-else :style="`color: ${isColorLight(space.primaryColour) ? 'black' : 'white'}`" >{{spaceInitials(space.title)}}</div>
              </div>
              <div><b>{{space.title}}</b></div>
            </div>
          </div>
        </div>

        <div class="form-group" style="margin-bottom: 1rem" v-if="customizeScreenOpen">
          <div><b>Customise Space Portal</b></div>
          <TextInput ref="portalNameInput" inset large label="Name the Space Portal" placeholder="Enter your portal name here" v-model="portalName"/>
          <p class="input__error" v-if="error.name">{{error.name}}</p>
        </div>

        <CustomiseSection
          v-if="customizeScreenOpen"
          :isCreate="true"
          customiseType="Portal"
          :startAngle="angle"
          :updateAngle="updateAngle"
          :backgroundColour="backgroundColour"
          :updateBackground="updateBackground"
          :backgroundImage="backgroundImage"
          :newImageUploaded="newImageUploaded"
          :imageRemoved="imageRemoved"
          :showName="showName"
          :updateShowName="updateShowName"
          :textColor="textColor"
          :updateTextColour="updateTextColour"
        />
      </form>

      <FooterButtons>
        <btn @onClick="cancelCreateRoom" colour="grey" :left="true"> <template #text>Cancel</template></btn>

        <btn @onClick="toggleCustomizeScreen" v-if="canBeCustomized(picked)" :disabled="(loadingCurrentSpaceData && customizeScreenOpen) || (currentTab === 'Portal' && !selectedSpaceData)" colour="ghost"> <template #text>{{ customizeScreenOpen ? "Hide Customise":"Customise"}}</template></btn>
        <btn v-if="currentTab === 'Room'" @onClick="openRoom" :disabled="!isValid" colour="green"> <template #text>Open Room</template></btn>
        <btn v-else @onClick="createJumpSpace" :disabled="!isValid || !selectedSpaceId" colour="green"> <template #text>Create Portal</template></btn>
      </FooterButtons>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModalHeader from '@/framework/modals/components/newModalHeader.vue'
import { TextInput, FooterButtons } from "@/framework"
import FormChangeWatcherMixin from "@/plugins/mixins/formChangeWatcher.js";
import { convertFromServerColourScheme } from "@/functions/colourSchemes.js";
import CustomiseSection from './CustomiseSection.vue'
import LoadingIcon from '@/components/global/loading/LoadingIcon.vue'
import { colorIsLight, initaliseAreaName } from '@/functions/utility.js';

export default {
  name: 'CreateRoomModal',
  components: { ModalHeader, TextInput, FooterButtons, CustomiseSection, LoadingIcon },
  mixins: [ FormChangeWatcherMixin ],
  data: function() {
    const colourScheme = convertFromServerColourScheme(this.$store.getters.getCurrentAreaColourScheme)
    return {
      name: '',
      url: '',
      error: {
        name: '',
        url: ''
      },
      customizeScreenOpen: false,
      picked: 'temp',
      textColor: 'white',
      showName: true,
      angle: -1,
      // the first number represents temp or fixed, 1 is temp, 0 is fixed (lifestyle)
      // the second number is the conferenceType:
      // Spacein = 1,    // Normal spacein video call
      // Link = 2,       // Link opening in a new tab
      // Internal = 4,   // IFrame content
      // Livestream = 5, // Livestream
      // 4th argument is default colour
      // 5th argument is whether this room type can be customized
      roomTypes: {
        temp: ['Temporary Meeting Room', 1, 1, colourScheme.temporary, true],
        fixed: ['Fixed Meeting Room', 0, 1, colourScheme.fixed, true],
        livestream: ['Livestream', 0, 5, colourScheme.livestream, true],
        embed: ['Embedded Link', 0, 4, colourScheme.link, true],
        extern: ['External Link', 0, 2, colourScheme.link, true],
      },
      backgroundColour: colourScheme.fixed,
      backgroundImage: "",
      // to be indexed by conferenceType
      linkTypes: {
        2: "URL (Link will open in a new tab)",
        4: "URL (Link must support Iframe)",
        5: "HLS m3u8 URL"
      },

      createOptions: ['Room', 'Portal'],
      currentTab: 'Room',
      loadingSpaces: false,
      userSpaces: [],
      selectedSpaceId: null,
      selectedSpaceData: null,
      loadingCurrentSpaceData: false,
      portalName: '',

      formChangeWatcherSettings: {
        // This tells FormChangeWatcherMixin to watch these inputs
        // so we know when to present the "Save" button
        inputs: [
          "backgroundColour",
          "backgroundImage",
          "picked",
          "name",
          "url",
          "textColor",
          "showName"
        ],
        defaults: {
          backgroundColour: '',
          backgroundImage: '',
          picked: '',
          name: '',
          url: '',
          textColor: '',
          showName: true,
        },

        promptOnLeave: false
      },
    }
  },
  watch: {
    name(){
      this.error.name = !this.name ? 'Room name is required' : ''
    },
    url(){
      this.error.url = !this.url && this.linkType ? 'Room URL is required' : ''
    },
    linkType() {
      if(!this.linkType) {
        this.error.url = ''
        this.url = ''
      }
    },
    picked: {
      handler(newVal) {
        // when the room type has changed,
        // If the user hasn't changed the backgroundColour then set it to the default colour for that room type
        if (!this.isInputDirty('backgroundColour')) {

          // we prevent the formChangeWatcher from seeing the backgroundColour change here
          // because it would include it in the dirtiedInputs otherwise
          this.ignoreChanges(() => {
              const [, , , backgroundColour] = this.roomTypes[newVal]
              this.backgroundColour = backgroundColour
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'current_user/data',
      userType: 'current_user/currentAreaPermissionNo',
      notInRoom: 'getIfCurrentUsersRoomIsHub',
      areaData: 'getCurrentAreaData',
      isPremiumSpace: 'getCurrentAreaPremiumFeaturesEnabled',
      currentSpaceId: 'getCurrentAreaId'
    }),
    livestreamDisabled: vm => !vm.areaData.features.premiumFeature,
    roomType: vm => vm.roomTypes[vm.picked],
    linkType(){
      let [,,conferenceType] = this.roomType
      let rtn = this.linkTypes[conferenceType]
      return rtn
    },
    isValid() {
      if (!this.name) return false
      if (this.linkType && !this.url) return false
      return true
    },
  },
  methods: {
    ...mapActions([
      'createSpaceHandler',
      'modal/close',
      'popups/nav/confirmCreateRoomWhenInMeeting',
      'analytics/createRoomCancelled',
      'analytics/createPortalCancelled',
      'analytics/createRoomClick',
      'analytics/createPortalClick',
      'analytics/createRoomCustomiseClick',
      'analytics/createPortalCustomiseClick',
      'getRootAdminAreas',
      'api/nav/getSpaceData',
      'createJumpSpaceHandler',
    ]),
    // todo: figure out how to apply analytics here
    createRoom(){
      this['modal/close']()

      let [, lifestyle, conferenceType] = this.roomType

      let createSpaceArgs = [this.name, this.url, lifestyle, conferenceType]

      if (this.canBeCustomized(this.picked)) {
        createSpaceArgs = [...createSpaceArgs, this.backgroundColour, this.backgroundImage, this.textColor, !this.showName, this.angle]
      }
      this.createSpaceHandler(createSpaceArgs)
    },
    openRoom() {
      let e = this.error
      if(e.name || e.url) return

      if(!this.notInRoom) this['popups/nav/confirmCreateRoomWhenInMeeting'](this.createRoom)
      else this.createRoom()
    },
    btnDisabled(key){
      return key === 'livestream' && this.livestreamDisabled
    },
    btnFull(key) {
      return key == 'temp'
    },
    canBeCustomized(key) {
      const [, , , , canBeCustomized] = this.roomTypes[key]
      return canBeCustomized
    },
    toggleCustomizeScreen() {
      this.customizeScreenOpen = !this.customizeScreenOpen
      if(this.customizeScreenOpen) {
        if(this.currentTab === 'Room') this['analytics/createRoomCustomiseClick']()
        else if(this.currentTab === 'Portal') this['analytics/createPortalCustomiseClick']()
      }
    },
    //customise handlers
    newImageUploaded(url) {
      this.backgroundImage = url
    },
    imageRemoved() {
      this.backgroundImage = ''
    },
    updateBackground(val) {
      this.backgroundColour = val
    },
    updateShowName(val) {
      this.showName = val
    },
    updateTextColour(val) {
      this.textColor = val
    },
    updateAngle(a) {
      this.angle = a.value
    },
    cancelCreateRoom() {
      if(this.currentTab === 'Room') this['analytics/createRoomCancelled']()
      else if(this.currentTab === 'Portal') this['analytics/createPortalCancelled']()
      this.closePopup()
    },
    closePopup() {
      this['modal/close']()
    },
    async switchTab(val) {
      this.customizeScreenOpen = false
      this.revertChanges()
      this.currentTab = val

      if(val === 'Portal') {
        this.loadingSpaces = true
        this.userSpaces = await this.getRootAdminAreas()
        this.userSpaces = this.userSpaces.filter(s => s.id !== this.currentSpaceId)
        this.loadingSpaces = false
        this['analytics/createPortalClick']()
      }
      this.loadingCurrentSpaceData = false
    },
    spaceInitials(title) {
      return initaliseAreaName(title)
    },
    isColorLight(colour) {
      return colorIsLight(colour)
    },
    async selectJumpSpace(id) {
      this.selectedSpaceId = id
      this.customizeScreenOpen = false
      this.loadingCurrentSpaceData = true
      let res = await this['api/nav/getSpaceData'](id)
      if(res?.success?.area) {
        this.selectedSpaceData = res.success.area
        //update customise params with space data
        this.angle = -1
        this.backgroundColour = this.selectedSpaceData.colourScheme.primaryColour
        this.backgroundImage = this.selectedSpaceData.banner || ''
        this.textColor = 'white'
        this.hideName = false
        this.portalName = this.selectedSpaceData.name
      }
      else this.userSpaces = []
      this.loadingCurrentSpaceData = false
    },
    createJumpSpace() {
      let spaceData = this.selectedSpaceData
      let data = {
        reference: this.selectedSpaceId,
        topic: this.portalName,
        description: spaceData?.description || '',
        baseAngle: this.angle,
        color: this.backgroundColour,
        imageUrl: this.backgroundImage,
        textColor: this.textColor,
        hideName: !this.showName
      }
      this.createJumpSpaceHandler(data)
      this.closePopup()
    },
  },
  created() {
    this.$emit('modalSettings', {
      hasCloseButton: false
    })
    this['analytics/createRoomClick']()
    this.name = (this.user.firstName + ' ' + this.user.lastName).trim()+"'s Room"
  }
}
</script>

<style scoped lang="scss">
  @import "~@/scss/components/modals/modal_forms.scss";
  @import '~@/scss/components/modals/create_room_form.scss';

  .header-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 30px;

    & .tab {
      margin-top: -30px;
      padding: 23px;
      text-align: center;
      font-weight: bold;
      font-size: 120%;
      cursor: pointer;

      &:first-child {
        margin-left: -30px;

        &.inactive-tab {
          border-bottom-right-radius: 17px;
        }
      }
      &:last-child {
        margin-right: -30px;

        &.inactive-tab {
          border-bottom-left-radius: 17px;
        }
      }

      &.inactive-tab {
        background: #000 !important;
        opacity: 0.8 !important;
      }
    }
  }

  .portal-section {
    & .info {
      display: grid;
      gap: 0.8rem;

      & .portal__title {
        font-size: 24px;
      }
      & p {
        font-size: 14px;
        color: #C7C7C7;
        line-height: 19px;
      }
    }
    & .spaces-list {
      background: $grey-7;
      padding: 0.5rem;
      border-radius: 15px;
      overflow: hidden;
      max-height: 25vh;
      margin-top: 0.8rem;

      & .error {
        text-align: center;
        padding: 1rem;
      }

      & .spaces {
        max-height: 25vh;
        overflow-y: auto;

        & .space {
          padding: 0.5rem;
          border-radius: 15px;
          display: grid;
          grid-template-columns: 3rem auto;
          grid-gap: 1rem;
          align-items: center;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover, &.current {
            background: rgba(44,44,44,0.5);
          }

          & .icon {
            overflow: hidden;
            background: white;
            border-radius: 1000rem;
            width: 3rem;
            height: 3rem;
            color: black;
            font-weight: bold;
            text-align: center;
            align-content: center;
            justify-items: center;
            display: grid;

            & .img {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
</style>
